<template>
    <modal ref="confirmar" titulo="Confirmar pedido" icon="confirmar-pedido" @guardar="confirmar_pedido">
        <div class="row mx-0 justify-center f-15">
            ¿Desea tomar el pedido?
        </div>
        <div class="row f-14 my-4 mx-0 justify-center">
            <div class="col">
                <p class="ml-2">Fecha de Entrega</p>
                <el-date-picker
                v-model="model.entrega_fecha"
                type="date"
                size="small"
                placeholder="Fecha"
                value-format="yyyy-MM-dd"
                value="yyyy-MM-dd"
                :clearable="false"
                :picker-options="pickerOptions"
                @change="set_horario"
                />
            </div>
            <div class="">
                <p class="ml-2">Hora de Entrega</p>
                <el-select v-model="model.entrega_horario" size="small" placeholder="Horario">
                    <el-option
                    v-for="item in horarios"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-tienda'
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            horarios:[],
            model:{
                entrega_fecha:'',
                entrega_horario:''
            }
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos/id_pedido',
            pedido: 'pedidos/pedidos/pedido',
        }),
    },
    methods: {
        toggle(){
            this.model.entrega_fecha = this.pedido.entrega_fecha
            this.model.entrega_horario = this.pedido.entrega_horario
            this.set_franja()
            this.$refs.confirmar.toggle()
        },
        async confirmar_pedido(){
            try {
                if(this.model.entrega_fecha < moment().format('YYYY-MM-DD')){
                    this.notificacion('Advertencia','Por favor seleccione una fecha valida','warning')
                    return
                }
                if(this.model.entrega_horario === ''){
                    this.notificacion('Advertencia','Por favor seleccione un horario','warning')
                    return
                }
                if(this.id_pedido === null || this.id_pedido === ''){
                    this.notificacion('Advertencia','Por favor seleccione un pedido','warning')
                    return
                }
                const {data} = await Pedidos.confirmar(this.id_pedido,this.model)
                this.$store.commit('pedidos/pedidos/pasar_a_confirmado',data.pedido)
                this.$store.dispatch('pedidos/pedidos/pedidos_historial', this.id_pedido)
                this.notificacion('Mensaje','Pedido confirmado correctamente','success')
                this.$refs.confirmar.toggle()

            } catch (e){
                this.error_catch(e)
            }
        },
        async set_franja(){
            try {
                const {data} = await Pedidos.get_horarios()
                data.horarios.map(h=>{
                    h.disabled = false
                })
                this.horarios = data.horarios

            } catch (e){
                this.error_catch(e)
            }
        },
        set_horario(fecha){
            let hoy = moment().format('YYYY-MM-DD')
            let hora = moment().hour()

            if(hoy === fecha){
                this.horarios.map(h=>{
                    if(h.fin < hora){
                        h.disabled=true
                    }
                })

            }else{
                this.horarios.map(h=>{
                    h.disabled=false
                })
            }

            this.model.entrega_horario = ''
        }
    }
}
</script>

<style lang="css" scoped>
</style>
